import React from "react";


 const Newsletter = () => {
	return (
    <div>
        {/* <div className="max-w-5xl mx-auto hidden md:block">
             <a href="https://lacocinasana.com/clubcocinasana/batchcooking-saludable/" target="_blank" rel="noopener"><img className="py-4 w-max" src="https://recetascocinasana.s3.eu-west-1.amazonaws.com/ANUNCIO_WEB_BATCHCOOKING_4e36a48ed5.jpg" alt="Meriendas sanas" /></a>
        </div>
        <div className="md:hidden">
            <a href="https://lacocinasana.com/clubcocinasana/batchcooking-saludable/" target="_blank" rel="noopener"><img className="py-4 w-max" src="https://recetascocinasana.s3.eu-west-1.amazonaws.com/ANUNCIO_WEB_BATCHCOOKINGMOVILES_e98c1afc0b.jpg" alt="Meriendas sanas" /></a>
        </div> */}
        <div className="bg-newsletter mt-8">
            <div className="max-w-5xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <div className=" text-3xl text-center font-poppins text-gray-900 sm:block sm:text-4xl">No te pierdas ninguna receta ¡suscríbete!</div>
                <div className=" text-base text-center  text-grey-600 sm:block sm:text-base mt-2">Suscríbete y descarga GRATIS el ebook "recetas al estilo La Cocina Sana"</div>
                <div className="grid grid-cols-1 gap-y-8 sm:grid-cols-2 gap-x-6  items-center justify-center">
                    <div>
                        <img
                            alt="batchcooking"
                            src="https://recetascocinasana.s3.eu-west-1.amazonaws.com/recetario_cocina_sana_680dc19004.jpeg"
                            className=" w-4/5 mx-auto md:w-full  md:p-0 cover"
                        /> 
                    </div>
                    <div>

                        <form method="POST" action="https://redcobot.activehosted.com/proc.php" className="mt-8 ">
                            <input type="hidden" name="u" value="9" />
                            <input type="hidden" name="f" value="9" />
                            <input type="hidden" name="s" />
                            <input type="hidden" name="c" value="0" />
                            <input type="hidden" name="m" value="0" />
                            <input type="hidden" name="act" value="sub" />
                            <input type="hidden" name="v" value="2" />
                            <input type="hidden" name="or" value="8eb0d90b70423f70578a59fb8583b59d" />
                            <ul className="list-none ">
                                <li className="mt-2 max-w-xs"><input type="text" id="firstname" name="firstname" placeholder="Escribe tu nombre" required className="w-full px-5 py-3 placeholder-gray-500 focus:ring-morado focus:border-morado  border-gray-300 rounded-md"/></li>
                                <li className="mt-2 max-w-xs"><input type="text" id="lastname" name="lastname" placeholder="Escribe tus apellidos" required className="w-full px-5 py-3 placeholder-gray-500 focus:ring-morado focus:border-morado  border-gray-300 rounded-md"/></li>
                                <li className="mt-2 max-w-xs"><input type="text" id="email" name="email" placeholder="Escribe tu email" required className="w-full px-5 py-3 placeholder-gray-500 focus:ring-morado focus:border-morado  border-gray-300 rounded-md"/></li>
                                <li className="mt-2 max-w-lg items-center flex"><input type="checkbox" id="field_1Acepto la política de privacidad" name="field[1][]" value="Acepto la política de privacidad" required/> <span className="ml-2">Acepto la <a href="/politica-de-privacidad" target="_blank" className="text-morado decoration-solid">política de privacidad</a></span></li>
                                <li className="mt-6 ">
                                    <div className="mt-8 rounded-md  sm:mt-0">
                                        <button
                                        id="_form_9_submit"
                                        className='w-20 flex items-center text-black justify-center px-5 py-3 border border-transparent text-base font-medium font-poppins rounded-md  bg-amarillofooter hover:bg-morado hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amarillotransparente'
                                        role='submit'>
                                            Enviar
                                        </button>
                                    </div>
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
            </div>
        </div>   
    </div>
 )
}

export default Newsletter;


import React from "react";
import Layout from "../components/layout";
import Newsletter from "../components/newsletter";


const Politica = () => {

    return (
        <Layout>
            <div class="relative py-16 bg-white overflow-hidden">
            <div class="relative px-4 sm:px-6 lg:px-8">
                <div class="text-lg max-w-prose mx-auto">
                <h1>
                    <span class="block text-base text-center text-morado font-semibold tracking-wide uppercase">LEGALES</span>
                    <span class="mt-2 block text-3xl text-center leading-8 font-bold tracking-tight text-black sm:text-4xl">Política de privacidad</span>
                </h1>
                </div>
                <div class="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
                <h4> ÍNDICE DE TÉRMINOS DE USO Y POLÍTICA DE PRIVACIDAD </h4>
                    <ul class="uk-list uk-list-bullet"> 
                    <li class="el-item">
                    <div class="el-content uk-panel uk-text-muted">A. RESUMEN DE TÉRMINOS DE USO Y POLÍTICA DE PRIVACIDAD</div></li>
                    <li class="el-item">
                    <div class="el-content uk-panel uk-text-muted">B. TÉRMINOS DE USO</div></li>
                    <li class="el-item">
                    <div class="el-content uk-panel uk-text-muted">C. POLÍTICA DE PRIVACIDAD</div></li>
                    <li class="el-item">
                    <div class="el-content uk-panel uk-text-muted">D. POLÍTICA DE COOKIES</div></li>
                    </ul>
                    <h4> A. RESUMEN DE TÉRMINOS DE USO Y POLÍTICA DE PRIVACIDAD </h4><div>En esta sección te explicamos cómo gestionamos la información; lo que hacemos, lo que no hacemos, a lo que nos comprometemos, y a lo que no podemos comprometernos porque así lo determine la ley, y el sentido común (si la ley lo permite). Puedes leer el texto legal a continuación si quieres más detalles, pero también creemos que es útil que te resumamos aquí lo que se desarrolla más abajo en dos secciones: Los Términos de Uso, y la Política de Privacidad.</div><div><ul>
                    <li>No usamos materiales gráficos o escritos sin permiso, y naturalmente no podemos permitir que otros sí lo hagan, así que por favor te rogamos que hagas un uso razonable de la información publicada en www.lacocinasana.com o en las webs accesibles a través de este enlace. La información que ofrecemos en nuestras páginas web está pensada para ser consultada por los usuarios como un servicio de información y orientación para el propio usuario, en abierto si son urls públicas, o privadamente si se trata de material no destinado al uso público. Evidentemente el usuario no está autorizado a reutilizar o manipular nuestros materiales con fines distintos a los que cumplen las webs de Redcobot S.L, y aunque puede reenviar a cualquier persona la referencia de una web de Redcobot S.L, no está autorizado a manipularla, extraer información con fines comerciales o con el fin de aprovecharse ilícitamente del esfuerzo ajeno.</li>
                    <li>La información que ofrecemos es válida con los límites razonables, es decir, que pueden contener alguna errata, aunque hacemos todo lo posible por evitarlo. No ofrecemos información ninguna que no esté autorizada en su caso por el legítimo propietario, y si crees que no es así te rogamos que nos lo indiques por los correos electrónicos que se detallan más abajo para poder solucionarlo inmediatamente.</li>
                    <li>No recogemos información sobre menores ni las webs de Redcobot S.L están destinadas a menores. Puede ser posible que exista una errata o alguna representación virtual no coincida exactamente con la realidad, pero en todo caso Redcobot S.L no asume ninguna relación contractual de ningún tipo con ningún usuario que pueda consultar una web gestionada por Redcobot S.L, mientras no exista un acuerdo firmado, escrito y específico, si fuera el caso.</li>
                    <li>En cuanto a la política de privacidad que se detalla más abajo, recogemos la información básica para analítica web, etc., con el objetivo de mejorar nuestros servicios, y lo mismo hacemos en el caso en que nos facilites información personal (detalles abajo), siempre con la idea de intentar mejorar nuestros servicios para que sirvan mejor al usuario. Lo hacemos con nuestra mejor intención y por supuesto comprometiéndonos a no hacer ningún uso indebido de tu información, y a facilitarte el caso de que no quieras que recojamos ninguna información tuya.</li>
                    <li>Si contactas con nosotros te contactaremos por el mismo medio que hayas escogido, en general para ampliar información que hayas podido solicitar si es el caso, o para informarte en otro sentido si procede. Te invitamos a disfrutar de las páginas gestionadas por Redcobot S.L con arreglo a las buenas prácticas habituales de internet, y por supuesto quedamos a tu disposición si crees que en algo podemos mejorar.</li>
                    <li>Nota: Este texto de Resumen de Condiciones Legales y Privacidad, así como el texto propio de las Condiciones Legales, y el de Privacidad, están disponibles en inglés en esta misma web (vid supra). En cualquier caso, el contenido de las webs de Redcobot S.L se regula según el derecho español, y por tanto cualquier usuario que acceda desde el extranjero a dichas webs debe tener este aspecto en cuenta. En caso de conflicto entre versiones, prevalece la versión en español.</li>
                    </ul></div>
                    <h4> B. TÉRMINOS DE USO </h4><div>Bienvenido a www.lacocinasana.com. Al visitar esta web le ofrecemos productos e información sobre servicios que presta Redcobot S.L, bajo los siguientes términos:</div><div><ul>
                    <li>Introducción</li>
                    <li>Privacidad</li>
                    <li>Comunicaciones electrónicas</li>
                    <li>Derechos de Propiedad/Uso de los Materiales de los Sitios Web, y derechos sobre la propiedad intelectual, bases de datos y asimilables.</li>
                    <li>Marcas Registradas y Reclamaciones sobre Propiedad Intelectual</li>
                    <li>Renuncia/Limite de la Responsabilidad</li>
                    <li>Su cuenta</li>
                    <li>Contenidos subidos por el usuario (Opiniones, comentarios, comunicaciones y otros contenidos) y software de Redcobot S.L</li>
                    <li>Vínculos</li>
                    <li>Exactitud de la información</li>
                    <li>No Renuncia</li>
                    <li>Jurisdicción</li>
                    <li>Precios</li>
                    <li>Menores de edad</li>
                    <li>Varios</li>
                    </ul></div>
                    <h4> Introducción </h4><div><p>Por favor lea atentamente los términos y condiciones (en adelante “Términos”) que rigen el uso (incluido el acceso, navegación y/o uso de cualquier elemento interactivo) de aquellas secciones del sitio web de Redcobot S.L accesibles a través de www.lacocinasana.com, así como cualquier otros sitios o páginas web de Redcobot S.L accesibles a través del sitio web de Redcobot S.L o de otros sitios web promocionales que alberga o patrocina Redcobot S.L (en adelante, los Sitios Web).</p>
                    <p>La cocina sana es una marca registrada de Red Cobot S.L.. Toda referencia a La cocina sana en cualquier sentido que implique identificación, propiedad u otros derechos, o responsabilidad societaria debe entenderse como referencia a dicha sociedad, Red Cobot S.L., con domicilio en la c/ Dulce Chacón, 33, 10E de Madrid, CP 28050 y CIF ESB88110150, teléfono +34 699 11 75 45, y email info [at] redcobot.com</p>
                    <p>Los Sitos Web se rigen por las leyes de España y esta sección -que contiene las condiciones legales- de la web www.lacocinasana.com (en adelante “la sección”) se dirige exclusivamente a usuarios ubicados en territorio español, a quienes está dirigido este sitio web, y se rige por la normativa española. Si usted accede a los mencionados sitios web desde fuera de España o en su caso aplica legislación distinta de la española debe tener presente lo indicado más arriba, pues este sitio web no está dirigido a esos casos. La sección es gestionada por Red Cobot S.L., con domicilio en Madrid, Calle Dulce Chacón, 33, 10E, con correo electrónico info [at] redcobot.com.</p>
                    <p>El uso de los Sitios Web está sujeto a dichos Términos legalmente vinculantes e implica la aceptación de los mismos y la obligación de actuar conforme a ellos. Nos reservamos el derecho de cambiar los términos en cualquier momento. Todo cambio será publicado en los Sitios Web y usted se compromete a aceptar dichos cambios. Al utilizar los servicios de Redcobot S.L, usted acepta quedar vinculado por estos Términos.</p></div>
                    <h4> Privacidad </h4><div>Por favor, revise nuestra Política de Privacidad, disponible en esta misma página más abajo y siempre a través de www.lacocinasana.com, que rige el modo en el que tratamos cualquier tipo de información personal que nos proporciona.</div>
                    <h4> Comunicaciones electrónicas </h4><div><p>Cada vez que utilice un Servicio de Redcobot S.L o nos envíe un correo electrónico, servicio de mensajería (sms, mensaje a través de Facebook, whatsapp, etc.) estará comunicándose por medios electrónicos con nosotros. Nosotros nos pondremos en contacto con usted mediante los datos de contacto que nos haya proporcionado, o a través de la publicación de avisos en nuestro sitio web, o mediante los otros Servicios de Redcobot S.L. De acuerdo con los presentes Términos de este contrato, usted acepta recibir comunicaciones de nuestra parte a través de medios electrónicos, y acepta que todos los avisos, términos, notificaciones y comunicaciones satisfacen cualquier requisito exigible a una comunicación escrita, salvo que la ley aplicable con carácter imperativo exija una forma distinta de comunicación.</p>
                    <p>En el caso de usted se comunique con Redcobot S.L por medios electrónicos de modo fortuito, o por canales no habilitados expresamente por Redcobot S.L explícitamente, Redcobot S.L no asume ninguna responsabilidad derivada de dichas situaciones.</p></div>
                    <h4> Derechos de Propiedad/Uso de los Materiales de los Sitios Web, y derechos sobre la propiedad intelectual, bases de datos y asimilables. </h4><div><p>Por favor, lea el resto de términos de uso aplicables a las páginas web a las que se accede a través de&nbsp;<a href="https://www.lacocinasana.com/">www.lacocinasana.com</a>&nbsp;que son gestionados por Redcobot S.L.</p>
                    <p>El uso de los Sitios Web está sujeto a dichos Términos legalmente vinculantes e implica la aceptación de los mismos y la obligación de actuar conforme a ellos. Nos reservamos el derecho de cambiar los términos en cualquier momento. Todo cambio será publicado en los Sitios Web gestionados por Redcobot S.L (en adelante los Sitios Web) y usted se compromete a aceptar dichos cambios.</p>
                    <p>Redcobot S.L ha sido debidamente autorizado por un tercero para usar material diverso en los Sitios Web o posee todos los derechos de autor relativos a todo el material de los Sitios Web. Redcobot S.L posee igualmente todas las marcas, nombres comerciales, nombres de servicio, logos o nombres de dominio utilizados en los Sitios Web o vinculados a ellos o posee la debida autorización para utilizar dicho material.</p>
                    <p>Igualmente, todo contenido alojado o puesto a disposición a través de los servicios de Redcobot S.L, como por ejemplo gráficos, logotipos, iconos de botones, imágenes, clips, documentos, datos, texto, información y elementos asimilables son propiedad de Redcobot S.L o Redcobot S.L dispone de permiso para su uso. Todos los elementos descritos están protegidos por la ley española, europea e internacional en cuanto a los derechos de propiedad intelectual, derechos de autor, y derechos sobre bases de datos. El conjunto del contenido está igualmente protegido por la legislación nacional e internacional.</p>
                    <p>No está permitido el uso de ninguna información, producto ni ningún servicio de Redcobot S.L sin el consentimiento escrito de Redcobot S.L, salvo en los casos en los que el uso del contenido corresponda a un acuerdo con Redcobot S.L para ofrecer la información a través de servicios ajenos a Redcobot S.L, y en ese caso así será explicitado en ese acuerdo previo. Muy en particular, no se permite de ningún modo y para ningún fin, comercial o no comercial, el uso de imágenes, fotografías, o diseños de Redcobot S.L en cualquier forma, sin previo consentimiento escrito, ni se permite la creación de bases de datos o en general el uso de información extraída de las webs que gestiona Redcobot S.L. Esto se aplica igualmente a imágenes, texto o cualquier material que Redcobot S.L esté autorizada a publicar por clientes de Redcobot S.L, con especial prohibición de utilizar fotos o cualquier otro material, sea propiedad de o gestionado por Redcobot S.L. Queda expresamente prohibida la reproducción de material de los Sitios Web que gestiona Redcobot S.L, de cualquier tipo, así como la manipulación e identificación de material gráfico de clientes de Redcobot S.L o personas allegadas a dichos clientes, como por ejemplo el uso de fotografías de una receta etiquetando dicho material con información sobre la receta, o información personal o de cualquier otro tipo.</p>
                    <p>Cualquier modificación o uso de los materiales de los Sitios Web para cualquier otro fin no autorizado de manera explícita, constituye una violación de los derechos de autor de Redcobot S.L y de otros derechos de la propiedad.</p>
                    <p>Ninguna parte de los Sitios Web puede copiarse, reproducirse, volver a publicarse, descargarse, publicarse en Internet, transmitirse o distribuirse de ninguna manera, sea cual fuere, excepto con fines de visionado, sin el consentimiento previo por escrito de Redcobot S.L. Esta prohibición incluye también la modificación de cualquier contenido de los Sitios Web mediante “framing”, así como la creación de vínculos no autorizados. No está permitido el uso de metadescripciones, etiquetas o elementos de programación análogos, ni de ningún otro "texto oculto" que utilice los nombres o marcas registradas de Redcobot S.L o cualquier material sin el consentimiento previo y por escrito.</p></div>
                    <h4> Marcas Registradas y Reclamaciones sobre Propiedad Intelectual </h4><div><p>Todas las marcas y patentes que se representan de algún modo en contenidos alojados en las webs gestionadas por Redcobot S.L son propiedad de Redcobot S.L. (estén concedidas o en trámite), o v tiene autorización para utilizarlas. No podrán utilizarse las marcas registradas ni la imagen comercial de La cocina sana, ni en particular ningún diseño, en relación con ningún producto o servicio que no pertenezca a Redcobot S.L, ni en ninguna forma que fuera susceptible de causar confusión entre los usuarios o que pueda menospreciar, desacreditar o en general causar cualquier tipo de confusión respecto a las marcas comerciales que gestiona Redcobot S.L. El resto de marcas registradas que no son propiedad de Redcobot S.L y que aparecen incluidos o están disponibles a través de los Servicios de Redcobot S.L pertenecen a sus respectivos propietarios, quienes podrán o no estar afiliados o relacionados de cualquier modo con Redcobot S.L, o patrocinados por Redcobot S.L.</p>
                    <p>Redcobot S.L respeta la propiedad intelectual de terceros y se esfuerza en respetar esos derechos. Si considera que sus derechos de propiedad intelectual han podido ser infringidos, le rogamos que se ponga en contacto con nosotros a través del correo electrónico info [arroba] redcobot.com.</p>
                    <p>En el caso de usted se comunique con Redcobot S.L por medios electrónicos de modo fortuito, o por canales no habilitados expresamente por Redcobot S.L explícitamente, Redcobot S.L no asume ninguna responsabilidad derivada de dichas situaciones.</p></div>
                    <h4> Renuncia/Limite de la Responsabilidad </h4><div><p>El uso y la navegación por los Sitios Web quedan bajo su responsabilidad. Ni Redcobot S.L ni ninguna otra parte que represente a Redcobot S.L o que sea matriz o subsidiaria de la misma en la creación o presentación de los Sitios Web es responsable de ningún daño directo, indirecto, especial, punitivo, fortuito, ejemplar o que resulte del uso que usted haga de los Sitios Web o de la información contenida en ellos.</p>
                    <p>En relación con el alcance permitido por la ley y sin perjuicio de lo anterior, todo lo que contienen los Sitios Web se proporciona tal cual, sin ninguna garantía, ya sea explícita o implícita, de ningún tipo, incluyendo, entre otras, las garantías de comercialización, de adecuación para un determinado propósito, de ausencia de virus o de no vulneración. Puesto que algunas jurisdicciones no permiten poner límites a garantías implícitas o limitar la responsabilidad por daños fortuitos o accidentales, puede que las limitaciones anteriormente descritas no le afecten. Le rogamos que se informe adecuadamente, y ponga en nuestro conocimiento cualquier aspecto que crea que debe tenerse en cuenta.</p>
                    <p>La información solicitada que envía a Redcobot S.L por correo electrónico u otro medio de comunicación para registrarse o inscribirse, hacer consultas, adquirir productos y/o enviar las correspondientes preguntas a los Sitios Web, si procede, está sujeta a nuestra política de privacidad.</p>
                    <p>Todas las opiniones, comentarios y otras informaciones enviadas, incluidas, las ideas, conceptos, técnicas, conocimientos y similares, no serán tratados como confidenciales por Redcobot S.L y Redcobot S.L podrá usar dichos materiales de la manera que considere apropiada. Para más información sobre este asunto, por favor lea nuestra Política de Privacidad y el punto 7 de los presentes Términos.</p>
                    <p>Redcobot S.L no es responsable de ningún modo y en ningún caso de productos, servicios o información que sea accesible a través de los Sitios Web que gestiona Redcobot S.L, pero que no sea gestionada por Redcobot S.L, como es el caso de información a la que se acceda por banners publicitarios presentes en los Sitios Web gestionados por Redcobot S.L, links de referencia a otras marcas o fuentes de información, etc. El acceso a otros sitios web a través de los Sitios Web que gestiona Redcobot S.L no supone en ningún caso la conformidad de Redcobot S.L con el uso que hagan esos otros sitios web de cualquier tipo de información, personal o comercial. Le sugerimos que revise con atención las condiciones y términos de cualquier sitio web al que acceda a través de los sitios web que gestiona Redcobot S.L. No asumimos responsabilidad alguna por las acciones, inacciones, productos y contenidos de ningún sitio web, empresa o individuo con los que usted pueda entrar en contacto a través de los Sitios Web que gestiona Redcobot S.L.</p>
                    <p>Redcobot S.L se esfuerza y esforzará al máximo para asegurar la disponibilidad de servicios, productos, y ausencia de errores en cualquier tipo de comunicación o publicación. Sin embargo usted acepta que esto no puede implicar ni implica la garantía de la ausencia de errores o imprevistos, que pueden traducirse en errores en la información, problemas en el uso de los sitios web que gestiona Redcobot S.L, o en problemas de disponibilidad a tiempo de productos o servicios. Redcobot S.L no asume ninguna responsabilidad por este tipo de sucesos.</p>
                    <p>En cuanto a información accesible a través de los sitios web de Redcobot S.L, y que de algún modo pueda relacionarse con la experiencia de un cliente directo o indirecto de Redcobot S.L, aspecto que es extensible a las relaciones contractuales directas con clientes, Redcobot S.L no es responsable de cualquier pérdida no atribuible contractualmente a incumplimiento contractual grave. Tampoco es responsable de pérdidas económicas, incluyendo lucro cesante, traumas, problemas psicológicos, o mermas en fondo de comercio, por problemas no expresamente previstos en la relación contractual con el cliente, que no puedan atenderse por causas fortuitas o ajenas a la voluntad de Redcobot S.L. Si la causa fuera imputable directamente a Redcobot S.L, y se debiera a una negligencia o error grave, aunque no al dolo, usted acepta que la responsabilidad de Redcobot S.L en ningún caso excederá de la devolución del precio del producto o servicio contratado. Esta disposición no afecta al derecho del cliente a recibir el producto o la prestación del correspondiente servicio en un plazo lo más breve posible. Redcobot S.L tampoco será en ningún caso responsable por ningún daño que un cliente considere que se haya producido en aspectos no comerciales como daños morales, psicológicos, o de naturaleza similar, por cualquier causa, y especialmente por cualquier problema derivado de que la información disponible a través de los sitios web, o a través de cualquier medio de comunicación, haya sido interpretada por el cliente de modos no conocidos ni previstos por Redcobot S.L, salvo naturalmente el caso de dolo.</p></div>
                    <h4> Su cuenta </h4><div><p>Si en alguno de los sitios web usted accede a la creación de una cuenta personal para apertura de un perfil personal, o cualquier acción asimilable destinada a tener un acceso individualizado a parte o el conjunto de los sitios web, cuando usted utiliza los servicios de Redcobot S.L es responsable de mantener la confidencialidad de los datos de su cuenta y contraseña así como de restringir adecuadamente el acceso a sus dispositivos informáticos (ordenador, teléfono móvil o cualquier otro a través del cual se pueda acceder a información personal) por parte de otras personas. Dentro del marco legal establecido, usted acepta asumir toda la responsabilidad que proceda por todas las actividades realizadas desde su cuenta o utilizando su contraseña, y es responsabilidad suya asegurar y salvaguardar la confidencialidad de los datos de acceso a su cuenta. Deberá igualmente informarnos inmediatamente en caso de que crea que otra persona puede disponer de los datos de acceso a su cuenta, o crea que es posible que eso suceda.</p>
                    <p>Es su responsabilidad facilitar datos correctos y completos, y tiene el deber de informarnos cuando esos datos cambien.</p>
                    <p>Usted acepta la prohibición de usar cualquier servicio de Redcobot S.L o información accesible a través de Sitios Web que gestiona Redcobot S.L en cualquier forma que pueda causar cualquier tipo de perjuicio a Redcobot S.L o a una tercera persona, y muy en particular a clientes de Redcobot S.L. Esto incluye de manera orientativa, pero no exhaustiva, el uso para dañar o acceder a bases de datos; interrupciones o alteraciones en el acceso a los servicios de Redcobot S.L; técnicas de spamming y asimilables; y en general para cualquier uso fraudulento o destinado a causar cualquier tipo de molestia a Redcobot S.L, clientes o proveedores de Redcobot S.L, o a un tercero que pueda verse afectado.</p>
                    <p>En el caso de que los servicios de acceso a cuentas personales estén activos, podrá acceder en todo momento a la información existente sobre Su Cuenta a través de&nbsp;<a href="https://www.lacocinasana.com/">www.lacocinasana.com</a>. En ausencia de comunicación expresa, usted acepta que Redcobot S.L no está obligada a mantener los servicios de acceso a cuentas personales, ni los datos generados por esa razón, más de 30 días, aunque a efectos de protección de datos se aplicará la legislación española.</p></div>
                    <h4> Contenidos subidos por el usuario (Opiniones, comentarios, comunicaciones y otros contenidos) y software de Redcobot S.L </h4><div><p>Usted y cualquier usuario de los sitios web de Redcobot S.L puede publicar opiniones, comentarios, sugerencias, ideas, u otros contenidos, así como subir información a los Sitios Web de Redcobot S.L, o gestionados por Redcobot S.L, si los sitios web así lo preven y permiten. Sea o no cliente de Redcobot S.L, cualquier información o solicitud de información que nos haga llegar por medios electrónicos procurará ser atendida, pero usted acepta que Redcobot S.L no adquiere ninguna obligación ni responsabilidad, distinta de la legalmente vigente en España, para responder a su publicación de contenido o solicitud de información.</p>
                    <p>Esto es especialmente aplicable a cualquier solicitud de información, reclamación, o comunicación efectuada a través de canales de comunicación distintos del correo electrónico disponible en www.lacocinasana.com. La información que llegue a Redcobot S.L a través de redes sociales, comentarios, publicación de opiniones, y medios similares, no tendrá carácter de obligación contractual para Redcobot S.L en ningún caso. Si usted desea obtener información que considere relevante para su relación con Redcobot S.L debe utilizar el correo electrónico facilitado a través de&nbsp;<a href="https://www.lacocinasana.com/">www.lacocinasana.com&nbsp;</a>o en esta sección.</p>
                    <p>Si los Sitios Web gestionados por Redcobot S.L lo permiten, cualquier usuario puede publicar su opinión, hacer comentarios, publicar contenidos, enviar comunicaciones, preguntas, ideas y cualquier información, siempre respetando el carácter no vinculante explicado en el párrafo anterior y siempre que ninguna parte o el conjunto de dicha información facilitada por el usuario no sea ofensiva, ni encubra objetivos comerciales o ilícitos que en ningún caso ampara Redcobot S.L. Esto incluye cualquier conducta ilícita, obscena, o intrusiva. No está permitido el uso de direcciones de correo falsas, ni la suplantación de identidad, ni en general cualquier conducta que afecte o pueda afectar negativamente a Redcobot S.L o a un tercero. Redcobot S.L podrá eliminar o modificar cualquier contenido subido por cualquier usuario, siempre respetando el marco legal, y en ningún caso será Redcobot S.L responsable de la conservación o mantenimiento de contenidos no estrictamente ligados a la relación contractual con el cliente,. Si cree que en algún momento se elimina o modifica un contenido subido por un usuario indebidamente, puede ponerse en contacto con Redcobot S.L a través de los canales facilitados para atención al cliente.</p>
                    <p>Al publicar cualquier contenido o subir cualquier información o material a los Sitios Web gestionados por Redcobot S.L, y salvo acuerdo explícito en contra, usted otorga a Redcobot S.L el derecho no exclusivo para utilizar , reproducir, modificar, adaptar, publicar, traducir, crear y explotar cualesquiera obras derivadas y distribuir y exhibir su contenido en todo el mundo y a través de cualquier medio, respetando los derechos de propiedad intelectual. También otorga usted a Redcobot S.L el derecho a utilizar el nombre facilitado por usted en relación con dicho contenido.</p>
                    <p>Al subir cualquier contenido a los sitios web gestionados por Redcobot S.L, usted declara y acepta que es usted el titular de los derechos que puedan ser atribuibles al contenido publicado, o está usted expresamente autorizado a su uso y en ese caso así deberá indicarlo. Usted se compromete a liberar a Redcobot S.L de cualesquiera reclamaciones presentadas por terceros contra Redcobot S.L derivadas de o en relación con dicho contenido y materiales, salvo en la medida en que dicha responsabilidad derive de la falta de supresión adecuada por nuestra parte de dicho contenido o material tras haber sido notificados del carácter ilícito de dicho contenido o material, y siempre que no exista negligencia grave.</p>
                    <p>Usted acepta utilizar el software que configura los Sitios Web gestionados por Redcobot S.L y sus partes vinculadas única y exclusivamente para utilizar y disfrutar de los Servicios que facilita Redcobot S.L. No está autorizado para usar parte o el conjunto de los componentes de software de los sitios web de Redcobot S.L, ni de bases de datos asociadas, en ningún modo. Esto incluye el diseño de la información y el diseño gráfico asociado al software. Redcobot S.L se reserva el derecho de cesar el acceso a sus Sitios Web, o cambiar el tipo de acceso, en cualquier momento, respetando los compromisos comerciales que existieran antes de dicho cese. Todo el Software de los sitios web de Redcobot S.L es propiedad de Redcobot S.L o Redcobot S.L tiene permiso para la utilización del mismo.</p>
                    <p>Al usar software gestionado por Redcobot S.L, es posible que esté utilizando servicios de terceros, que pueden estar sujetos a políticas de uso adicionales, así como a tasas u otras condiciones.</p>
                    <p>Aviso importante: A través de los Sitios Web gestionados por Redcobot S.L es posible (pero no obligatorio) que se pueda acceder a los distintos canales de comunicación gestionados por Redcobot S.L a través de servicios de terceros, como por ejemplo redes sociales como Facebook, Instagram, Twitter, Pinterest, Googe+, etc., que tienen sus propias normas que le recomendamos que consulte. En todo caso, utilizar dichas redes para hacer mal uso de la información proporcionada por Redcobot S.L, o por clientes , colaboradores, empresas o personas afines a Redcobot S.L, con fines ilícitos, de difamación, o que en general resulten perjudiciales para Redcobot S.L y/o cualquier persona que pueda verse relacionada puede dar lugar a responsabilidades tanto civiles como penales. Si tiene dudas al respecto, le recomendamos que consulte con su asesor legal. Le agradecemos que si en cualquier momento usted detecta información errónea, con algún vicio, no debidamente accesible o con cualquier otro problema en los Sitios Web que gestiona Redcobot S.L nos lo haga saber en el correo electrónico info [arroba] redcobot.com para poder solucionarlo.</p></div>
                    <h4> Vínculos </h4><div>Redcobot S.L no se hace responsable de los sitios web de terceros a los que se accede mediante vínculos a los que llevan o de los que proceden los Sitios Web, incluyendo la posibilidad de que dichos vínculos sean un error o hayan sido objeto de alguna técnica de hacking para dirigir al usuario a un producto o servicio con algún vicio. Redcobot S.L no respalda ningún sitio, bien o servicio ofrecido por dichos sitios. Redcobot S.L declina cualquier responsabilidad relativa a la exactitud de cualquier información de dichos sitios y de cualquier bien o servicio que se ofrezca o que se pueda comprar en dichos sitios.
                    <strong>Red Cobot S.L</strong>. Participa en el<strong> programa de afiliados de Amazon</strong>, por lo que recibe una comisión por cada compra que se realice en Amazon cuando se accede desde un link de la página lacocinasana.com.&nbsp; Al hacer “clic” en el botón “Comprar Ahora”&nbsp;<strong>Red Cobot S.L.</strong>&nbsp;le redirigirá a la página donde se ofertan los productos que mostramos al usuario en nuestra web.&nbsp;<strong>Red Cobot S.L.</strong>&nbsp;únicamente proporciona y facilita al usuario los enlaces a las páginas y/o plataformas de estos terceros donde pueden adquirirse los productos que mostramos, en aras a facilitar la búsqueda y fácil adquisición de los mismos por parte del usuario, pero en ningún caso estas páginas enlazadas y pertenecientes a terceros han sido revisadas ni son objeto de controles, ni recomendación por parte de&nbsp;<strong>Red Cobot S.L</strong>, por lo que en ningún caso&nbsp;<strong>Red Cobot S.L</strong><strong>&nbsp;</strong>será considerada responsable de los contenidos de estos sitios web, de las responsabilidades derivadas de su uso en todos los ámbitos, ni por las medidas que se adopten relativas a la privacidad del usuario, tratamiento de sus datos de carácter personal u otras que pudieran establecerse.</div>
                    <h4> Exactitud de la información </h4><div><p>Redcobot S.L toma medidas para garantizar la exactitud de la información de los Sitios web. No obstante Redcobot S.L, no se responsabiliza de los errores u omisiones en el contenido de los Sitios Web y fuera de los criterios de razonable diligencia, no garantiza la exactitud, integridad o actualidad de la información de los Sitios Web, en particular precios, características de productos o servicios, y en general cualquier tipo de información que pueda contener un error.</p>
                    <p>La información de los mismos está sujeta a cambios que pueden realizarse en cualquier momento y sin previa notificación. Usted acepta expresamente que en caso de existir una información incorrecta, Redcobot S.L no estará obligada contractualmente en ningún caso ni de ningún modo por esos posibles errores o cambios, aunque Redcobot S.L sí procurará informarle adecuadamente del hecho y de la posibilidad de proceder con el uso de que se trate con la información correcta.</p></div>
                    <h4> No Renuncia </h4><div><p>El hecho de que Redcobot S.L no haga cumplir cualquier parte de dichos Términos no constituirá la renuncia a ninguno de los derechos de Redcobot S.L mencionados en el presente texto derivados de acciones pasadas o futuras.</p></div>
                    <h4> Jurisdicción </h4><div><p>Cualquier controversia relativa al contenido o uso de los Sitios Web se regirá por las leyes de España y en cuanto a la jurisdicción y el lugar, sólo serán competentes para dirimir cualquier tipo de demanda los tribunales de la ciudad de Madrid.</p></div>
                    <h4> Precios </h4><div><p>Precios y disponibilidad: Todos los precios definidos en los Sitios Web gestionados por Redcobot S.L que se refieran a productos o servicios que ofrece Redcobot S.L, o relacionados con terceros, incluyen los impuestos legalmente aplicables (IVA incluido) salvo que se especifique de otro modo. En caso de que Redcobot S.L publique un precio de un producto o servicio, dicha publicación no conlleva obligación alguna ni por parte de Redcobot S.L ni por parte de un tercero relacionado con Redcobot S.L, y usted declara como usuario aceptarlo. Estos precios se comunican a modo informativo y en ningún caso tendrán valor contractual. Corresponden a los precios establecidos por Redcobot S.L en su caso y pueden variar sin previo aviso, muy particularmente en los casos de servicios realizados para un cliente o relacionados con terceros, especialmente en el caso de tratarse de información sobre precios que dependa de un tercero y se publicite en alguna de las webs gestionadas por Redcobot S.L. Los errores en precios estarán regulados por lo descrito en las presentes condiciones. Redcobot S.L no da información sobre disponibilidad salvo especificación en contra, y de modo análogo al precio, en caso de existir alguna información se trataría de información meramente orientativa y sujeta a cambios.</p>
                    <p>Usted declara conocer que es posible que exista alguna vez algún error en los precios, a pesar de los esfuerzos de Redcobot S.L por evitar que existan precios incorrectos publicados en los Sitios Web que gestiona Redcobot S.L, o en las comunicaciones electrónicas o de otro tipo que Redcobot S.L pueda tener con usted.</p>
                    <p>Redcobot S.L se reserva el derecho de modificar unilateralmente los precios de cualquier producto o servicio sin necesidad de previo aviso, respetando los precios vigentes hasta el cambio para productos o servicios pedidos y pagados, siempre que no se trate de un error en el precio.</p></div>
                    <h4> Menores de edad </h4><div><p>Redcobot S.L no ofrece directamente productos ni servicios a menores, aunque sí puede hacerlo a adultos a cargo de menores que estén interesados en servicios relacionados con dichos menores. Redcobot S.L no ofrece información para su consulta por parte de menores.</p></div>
                    <h4> Varios </h4><div><p>El conocimiento y aceptación de los presentes Términos junto con nuestra Política de Privacidad, constituye un acuerdo total entre usted y Redcobot S.L respecto al uso de los Sitios Web, y sobre la relación que pueda tener la información accesible a través de los sitios web con relación contractual directa de otro tipo entre cualquier persona física o jurídica y Redcobot S.L.</p>
                    <p>Redcobot S.L podría cambiar dichos Términos en cualquier momento mediante la publicación de los cambios en los Sitios Web. Dichos cambios se harán efectivos inmediatamente tras publicarlos en los Sitios Web. El uso de los Sitios Web implica su conformidad con cualquier Término modificado.</p>
                    <p>Los Sitios Web no pretenden proporcionar ninguna información que afecte a la salud. Todas las recomendaciones e información alojada en los sitios Web que gestiona Redcobot S.L, que no sea información concreta sobre productos o servicios que gestiona directamente Redcobot S.L y de los que Redcobot S.L sea directamente responsable, o conductas en general, se facilitan como recomendaciones u opiniones sin que ello obligue contractualmente a Redcobot S.L de ningún modo.</p>
                    <p>Aviso importante: facilitar información falsa, engañosa o incorrecta en sus comunicaciones con Redcobot S.L o haciendo uso de los servicios de Redcobot S.L puede dar lugar a responsabilidades tanto civiles como penales. Si tiene dudas al respecto, le recomendamos que consulte con su asesor legal.</p>
                    <p>Redcobot S.L se esfuerza en ofrecer información completa sobre los productos y servicios que pueda ofrecer, pero Usted declara conocer y aceptar que la información existente en los Sitios Web pueda no corresponder exactamente al producto o servicio descrito, aunque en todo caso Redcobot S.L hará sus mejores esfuerzos para la subsanación de cualquier error tan pronto se conozca.</p>
                    <p>La información sobre los servicios y productos ofrecida a través de las webs gestionadas por Redcobot S.L es orientativa y no pretende tener carácter contractual. Si tiene dudas sobre la información, adecuación, compatibilidad, o cualquier otro aspecto, por favor póngase en contacto con Redcobot S.L en su email de atención al cliente que encontrará en www.lacocinasana.com</p>
                    <p>Redcobot S.L se reserva el derecho de modificar las condiciones en cualquier momento. Si alguna de las presentes condiciones fuera declarada inválida, nula o por cualquier causa ineficaz, dicha condición se entenderá excluida sin que dicha declaración pueda afectar a la validez ni a la exigibilidad del resto de condiciones.</p>
                    <p>En caso de incumplimiento por su parte de las presentes Condiciones, y aún cuando Redcobot S.L pudiera no ejercitar cualesquiera derechos a su alcance en dicho momento, Redcobot S.L podrá hacer uso de tales derechos y acciones en cualquier momento según lo permita la ley vigente, y también en otra ocasión en la que usted pudiera incumplir las presentes Condiciones de Venta.</p>
                    <p>Cualquier controversia relativa a estos términos se regirá por las leyes de España y en cuanto a la jurisdicción y el lugar, sólo serán competentes para dirimir cualquier tipo de demanda los tribunales de la ciudad de Madrid.</p>
                    <p>Cualquier comunicación debe ser dirigida &nbsp;a Redcobot S.L - c/ Dulce Chacón, 33, 10E&nbsp; - 28050 -, correo electrónico paola [arroba] lacocinasana.com</p>
                    <p>Muy en particular, y complementariamente a las condiciones descritas en las Condiciones de Uso de los Sitios Web, no se permite de ningún modo y para ningún fin, comercial o no comercial, el uso de productos, imágenes de productos, fotografías, réplicas, o diseños de Redcobot S.L que hayan sido obtenidos a partir de productos o servicios de Redcobot S.L,o actividades de naturaleza análoga, en cualquier forma, sin previo consentimiento escrito, ni se permite la creación de bases de datos o en general el uso de información extraída de los productos comercializados por Redcobot S.L.</p>
                    <p>Cualquier modificación o uso de los productos de Redcobot S.L para cualquier fin no autorizado de manera explícita, constituye una violación de los derechos de autor de Redcobot S.L y de otros derechos de la propiedad.</p>
                    <p>Ningún servicio o producto de Redcobot S.L puede copiarse o reproducirse total o parcialmente sin el consentimiento previo por escrito de Redcobot S.L.</p>
                    <p>MEDIANTE EL USO DE LOS SITIOS WEB, USTED EXPRESA SU CONOCIMIENTO Y CONFORMIDAD CON ESTOS TÉRMINOS Y CONDICIONES.</p>
                    <p>ULTIMA ACTUALIZACIÓN: Julio de 2018</p>
                    <p>© Redcobot S.L, 2018 Todos los derechos reservados.&nbsp;</p></div>
                    <h4> C. POLÍTICA DE PRIVACIDAD </h4><div><ul>
                    <li>Privacidad</li>
                    <li>Recogida y Uso de la Información</li>
                    <li>Transmisión de Información Personal</li>
                    <li>Información No Solicitada e Información sobre Terceros</li>
                    <li>Seguridad</li>
                    <li>Modificación de su Información Personal y No Participación en las Comunicaciones de Marketing</li>
                    <li>Control de modificaciones corporativas</li>
                    <li>Privacidad infantil</li>
                    <li>Modificaciones de esta Política de privacidad</li>
                    <li>Derecho a la Intimidad</li>
                    <li>Contacte con nosotros</li>
                    </ul></div>
                    <h4> Privacidad </h4><div><p>Redcobot S.L protege la privacidad de todo consumidor (en adelante “usted” o “el usuario”) que visite las webs que alberga o patrocina Redcobot S.L o cualquier de sus marcas registradas con relación directa.</p>
                    <p>Según se expone en los Términos de Uso, lacocinasana.com es una marca registrada de Red Cobot S.L.. Toda referencia a Lacocinasana en cualquier sentido referido a política de privacidad debe entenderse como referencia a dicha sociedad, Red Cobot S.L., con domicilio en la c/ Dulce Chacón, 33, 10E de Madrid, CP 28050 y CIF ESB88110150, teléfono +34 699117545, y email info [at] redcobot.com</p>
                    <p>Consideramos es importante informarle acerca del uso que le damos a su información personal y que usted pueda elegir cómo quiere que se traten estos datos. Le pedimos e invitamos a que lea con detenimiento esta Política de Privacidad.</p>
                    <p>Esta Política de Privacidad está dirigida y se aplica a las personas que visitan las secciones del sitio web de Redcobot S.L accesibles a través de www.lacocinasana.com con relación directa de ella, así como cualquier otro sitio web de Redcobot S.L o páginas promocionales, redes sociales, etc., accesibles a través del sitio web de Redcobot S.L o de otros sitios web promocionales que alberga o patrocina Redcobot S.L directamente. En lo sucesivo se usará la referencia a los Sitios Web englobando los casos mencionados en este párrafo y los que no estén previstos explícitamente pero sean asimilables.</p>
                    <p>Se aplicará la normativa española en lo relativo a la recogida de datos personales a través de los Sitios Web (tal y como figura en la sección 2.a.). La normativa española así como la de terceros países a los que se envíe su información personal puede que no tenga un nivel de protección equivalente al proporcionado por las leyes de su país de origen, en el caso de que acceda a los Sitios Web, que están dirigidos a consumidores españoles, desde fuera de España.</p>
                    <ul>
                    <li>2. Recogida y Uso de la Información</li>
                    <li>a. Información Personal</li>
                    </ul>
                    <p>Los usuarios pueden visitar nuestra página web navegando de manera anónima. Recopilamos información personal identificable (“Información Personal”) de los usuarios de los sitios cuando voluntariamente la ceden para realizar compras en los Sitios Web (si procede), cuando se registran o se inscriben voluntariamente en los Sitios Web o se ponen en contacto con nosotros mediante esta vía. Los usuarios de los Sitios Web pueden ceder su Información Personal por varias razones, entre las que se incluyen, entre otras, suscripciones a boletines, información sobre productos, información sobre eventos y servicios, encuestas, participación en concursos y otras acciones promocionales y para realizar compras (si procede). El tipo de Información Personal que se podría recoger incluye nombre, dirección, teléfono, dirección de correo electrónico e información relativa a las compras si fuera el caso, así como, en el caso de que procediera, información sobre la tarjeta de crédito o de débito que se utilice para facilitar la venta de productos de Redcobot S.L (si procede). Igualmente podríamos utilizar la información que nos proporcione para crear un perfil de usuario o consumidor para enviarle información sobre productos o eventos que puedan ser de su interés y para hacer más eficientes sus futuras consultas o compras (si procede). Es posible que combinemos la Información Personal procedente de los Sitios web con otra información sobre usuarios que no proceda de Internet para confeccionar estrategias de marketing o contenido de sitios web o para otros fines tales como estudios internos. Para consultar la información acerca de cómo evitar que se utilice su Información Personal con fines de marketing, consulte la sección 6.</p></div>
                    <h4> Información No Personal </h4><div><p>Cuando consulta los Sitios Web recogemos cierta información general y no personal. Esta información no se refiere a ningún usuario concreto. Es información relativa, por ejemplo, a cuantos usuarios visitan los Sitios Web o a qué páginas acceden. El hecho de recoger esta información nos ayuda a adaptar nuestros Sitios Web a nuestros visitantes y ofrecerles una mejor experiencia. Cuando visita los Sitios Web para navegar, leer o descargarse información, recogemos automáticamente información estándar del equipo que su navegador envía a todas las páginas web que visita, como por ejemplo, dirección IP, tipo de navegador e idioma, tiempos de acceso o las ya mencionadas direcciones de las páginas web. Generalmente no vinculamos la información estándar del equipo con nada que nos permita identificar a los usuarios, excepto para hacer cumplir nuestra normativa o condiciones de servicio o para proteger nuestro servicio, sitios web, usuarios u otros aspectos. Igualmente podríamos recoger información general y no personal a través de “cookies” o “web beacons,” tal y como se explica a continuación.</p>
                    <p>Cookies: las cookies son datos que los sitios web envían a su servidor y que posteriormente se almacenan en su sistema informático. Las cookies se reenvían al sitio web cuando el usuario visita de nuevo el mismo. Las cookies nos informan, por ejemplo, de si es la primera vez que el usuario visita el Sitio Web, o si lo ha hecho con anterioridad y qué secciones son las que más le interesan. El hecho de que los Sitios web almacenen cookies ayuda a mejorar su experiencia online ya que nos permite guardar sus preferencias para su próxima visita. Nuestras cookies también miden la actividad general de los Sitios Web, lo que nos permite saber qué áreas o funciones son las que mejor información ofrecen y cuáles son mejorables. Esta información nos permite mejorar nuestros Sitios Web para ofrecer mejores servicios a los usuarios.</p>
                    <p>Muchos navegadores están programados para aceptar cookies. No obstante, puede volver a configurar su navegador para que deshabilite las cookies o para que le informe de cuando se envía una cookie. Si elige rechazar las cookies es posible que esto afecte parcialmente a la funcionalidad de los Sitios web.</p>
                    <p>Web Beacons: las Web beacons, también conocidas como “clear gifs,” “one-pixel gifs,” “web bugs,” “Internet tags” or “pixel tags,” son pequeñas imágenes gráficas que nos permiten a nosotros y a terceros controlar la actividad de los Sitios Web y hacer que las cookies sean más efectivas.</p>
                    <p>Las web beacons nos permiten a nosotros o a terceros que actúan en nuestro nombre, medir y controlar la efectividad de nuestras campañas de marketing mediante la transmisión de información sobre si usted cliquea los links de nuestros e-mails o páginas web y reúne información sobre los sitos web que visita tras visitar los nuestros. Utilizamos archivos de registro para guardar los datos que recopilamos a través de web beacons.</p></div>
                    <h4> Transmisión de Información Personal </h4><div><p>De conformidad con los siguientes párrafos y sus contenidos, no facilitaremos ni venderemos ninguno de sus datos a ninguna empresa no subsidiaria o tercero sin su consentimiento previo a menos que (i) nos sea requerido por ley o debido a un procedimiento judicial; o (ii) nos sea requerido por las autoridades o funcionarios del estado.</p>
                    <p>Podremos nombrar a terceros proveedores de servicios para que administren y/o realicen ciertas funciones o servicios en nuestro nombre, para cuyo desempeño podría solicitarse el envío de información proporcionada por usted. Dichas funciones o servicios incluyen por ejemplo, la gestión de los Sitios Web, el envío de productos que usted haya solicitado o pedido y el envío de comunicaciones sobre productos, servicios o eventos especiales. Cualquier tipo de envío estará sujeto a compromisos de confidencialidad y seguridad y se realizará únicamente para el desempeño de dichas funciones o servicios.</p>
                    <p>Igualmente podemos compartir su Información Personal con terceros para acciones conjuntas de marketing o promociones de nuestros productos, servicios o eventos que puedan ser de su interés. Cualquier tipo de transmisión de Información Personal estará sujeta a compromisos de confidencialidad y seguridad y se realizará únicamente para el desempeño de dichos compromisos. Para consultar la información acerca de cómo evitar que se utilice su Información Personal con fines de marketing, consulte la sección 6. No obstante, debe saber que incluso si opta por no permitirnos usar su información para campañas de marketing, podríamos seguir enviando cierta información a terceros para cumplir con obligaciones legales. Por ejemplo, si ha optado por no recibir nuestros correos electrónicos podríamos tener que proporcionar su dirección de correo electrónico a un tercero con el que estemos realizando alguna promoción para que cumpla con su petición de no recibir correos electrónicos.</p></div>
                    <h4> Información No Solicitada e Información sobre Terceros </h4><div><p><strong>4a. Información no Solicitada</strong></p>
                    <p>A través de nuestros Sitios Web, le pedimos que nos proporcione cierto tipo de información para mejorar nuestro servicio. La información solicitada puede tratarse de preguntas y comentarios acerca de nuestros productos. Por favor, no nos envíe información sobre, entre otros, negocios, publicidad, creaciones, marketing o cualquier otro tipo de propuestas, conceptos, conocimientos, ideas y similares (“Información No Solicitada”).</p>
                    <p>Toda Información No Solicitada, incluyendo cualquier Información Personal no solicitada (por ejemplo, Información Personal enviada no a través de una petición específica para ese tipo de información sino en un cuadro de texto para escritura libre), que recibamos no estará sujeta a esta Política de Privacidad. Mediante el envío de cualquier Información No Solicitada, usted acepta que dependa de nuestro juicio el cómo utilizar esta Información No Solicitada y automáticamente nos concede un permiso perpetuo y exento de derechos de autor para este fin. En el caso de que cualquier Información No Solicitada que nos envía esté protegida por un derecho de autor, marca registrada o cualquier otro tipo de derechos de patentes, usted se compromete a indemnizar, defender y exonerar de la responsabilidad a Redcobot S.L, sus empresas matrices, subsidiarias y/o filiales, y a sus respectivos trabajadores, directivos, empleados, agentes, licenciadores, representantes y terceros proveedores (denominados conjuntamente los “Indemnizados Redcobot S.L”) en el caso de que se interpongan demandas o acciones contra cualquiera de los Indemnizados Redcobot S.L con motivo o relacionado con el uso o posesión de dicha Información No Solicitada.</p>
                    <p><strong>4b. Información sobre Terceros</strong></p>
                    <p>En los casos en que usted nos envíe Información escrita o gráfica por cualesquiera motivos, o participe en alguna de las acciones de comunicación o difusión de Redcobot S.L, y lo haga aportando imágenes en los que aparezcan representadas terceras personas, Usted acepta, mediante el envío de esa información, que nosotros podamos hacer uso de esa información para los fines descritos en la acción de que se haya tratado, o para los fines que se le hayan comunicado. En el caso de que cualquier tipo de información sobre terceros aportada por Usted esté protegida por un derecho de autor, marca registrada o cualquier tipo de derecho de patentes o asimilables, o en el caso de que contenga información gráfica, escrita o audiovisual sobre terceros, usted se compromete a indemnizar, defender y exonerar de la responsabilidad a Redcobot S.L, sus empresas matrices, subsidiarias y/o filiales, y a sus respectivos trabajadores, directivos, empleados, agentes, licenciadores, representantes y terceros proveedores (denominados conjuntamente los “Indemnizados Redcobot S.L”) en el caso de que se interpongan demandas o acciones contra cualquiera de los Indemnizados Redcobot S.L con motivo o relacionado con el uso o posesión de dicha Información.</p></div>
                    <h4> Seguridad </h4><div><p>Guardamos la información que recogemos en servidores cumpliendo con las medidas de seguridad requeridas por la legislación española sobre protección de datos para proteger su Información Personal. No obstante, no podemos garantizar absolutamente la seguridad total, y esto no significa que nuestro nivel de seguridad cumpla o exceda cualquier tipo de estándar específico (aparte de los exigidos por la legislación sobre protección de datos).</p>
                    <p>Ninguna transmisión de Internet es 100% segura o está libre de errores y tampoco los datos guardados son invulnerables. No es posible garantizar absoluta y completamente la seguridad de nuestros sitios web, bases de datos o servicios así como tampoco podemos garantizar que la información que nos envíe no sea interceptada mientras nos la está transmitiendo a través de Internet. No somos responsables de las acciones de terceros.</p></div>
                    <h4> Modificación de su Información Personal y No Participación en las Comunicaciones de Marketing </h4><div><p>Si desea modificar su Información Personal, puede acceder a su perfil personal online, si existiera y fuera accesible, y cambiar los datos registrados. En cualquier caso, puede ponerse en contacto con nosotros en el +34 699 11 75 45 para que le ayudemos a modificar su Información Personal. También puede escribirnos al correo electrónico info [arroba] redcobot.com</p>
                    <p>Podría decidir cancelar la subscrición a los correos electrónicos de marketing enviando un correo electrónico en la sección de contáctenos o utilizando el vínculo para cancelar la subscripción que encontrará en nuestros correos electrónicos de marketing. Todas las peticiones tardan en hacerse efectivas varias semanas. Si no desea participar en ninguna de las comunicaciones de marketing (no solo en las enviadas a través de e-mail), por favor póngase en contacto con nosotros en el +34 699 11 75 45</p>
                    <p>Incluso si decide no participar en las comunicaciones de marketing, podríamos seguir poniéndonos en contacto con usted para enviarle información relevante sobre operaciones de administración de su perfil personal u otro tipo de información no relacionada con el marketing. Para más información acerca de nuestras políticas sobre los Sitios Web, por favor lea los términos de uso.</p></div>
                    <h4> Control de modificaciones corporativo </h4><div><p>La Información Personal recogida en los Sitios Web podría ser transferida como parte de una fusión, adquisición o venta de los activos de la compañía, así como, en el improbable caso de una insolvencia, bancarrota o suspensión de pagos, en los que la Información Personal sería transferida a otra entidad.</p></div>
                    <h4> Privacidad infantil </h4><div><p>Los Sitios Web, incluyendo los canales de redes sociales asociadas a ellos, no están dirigidos a niños. Esto incluye cualquier apartado de cualquier web gestionada por Redcobot S.L que contuviera información de interés para niños, si estuviera activa, puesto que no está dirigida a niños sino a sus padres, familiares, conocidos o amigos que sean mayores de edad y puedan visitar nuestros Sitios web con fines relativos a esos niños. Las páginas web están dirigidas, salvo especificación en contra, a un público adulto, y en el caso de que contengan artículos o información dirigida a niños esto se referirá a información para los padres, familiares, conocidos o amigos que sean mayores de edad y puedan visitar nuestros Sitios web con fines de interés para los niños. No recogemos ni conservamos consciente ni intencionadamente ningún tipo de Información Personal de niños menores de 18 años. Si cree que tenemos cualquier tipo de Información Personal procedente de niños menores de 18 años o relativa a ellos, por favor, póngase en contacto con nosotros por las vías indicadas en la sección 11 que encontrará más abajo.</p></div>
                    <h4> Modificaciones de esta Política de privacidad </h4><div><p>Nos reservamos el derecho de modificar, cambiar o actualizar de cualquier forma, a nuestro juicio y en todo momento esta política y usted consiente que se le vincule a dichas modificaciones, cambios o actualizaciones. Le informaremos sobre los cambios sustanciales en esta Política de Privacidad publicando la política actualizada con la fecha en la que se revisó en esta página; si se encuentra registrado, le enviaremos un correo electrónico a la dirección que tengamos registrada. Su uso continuado (incluyendo acceso, visita y/o uso de dispositivos interactivos) de nuestros Sitios constituye su conformidad con esta Política de Privacidad y sus actualizaciones. Le invitamos a que consulte periódicamente esta Política de Privacidad para mantenerse informado acerca de cómo protegemos la Información Personal que recogemos.</p></div>
                    <h4> Derecho a la Intimidad </h4><div><p>Usted tiene reconocidos los derechos de acceso, rectificación, supresión y oposición en lo que respecta a su Información Personal. Podrá ejercer sus derechos de acceso, rectificación, supresión y oposición, enviándonos un correo electrónico al email indicado en la sección de Contacto de la Web. Las peticiones para realizarlo que se envíen a través de otros canales puede que tarden más tiempo en responderse.</p></div>
                    <h4> D. POLÍTICA DE COOKIES </h4>
                    <p>Para ver la información detallada acerca de las cookies usadas en la plataforma, puede consultar el siguiente enlace <a href="https://lacocinasana.com/clubcocinasana/politica-de-cookies/" target="_blank">Detalle de las cookies</a> </p>


                    <h4> Contacte con nosotros </h4><div><p>Si tiene cualquier pregunta o comentario sobre esta Política de Privacidad o desea que modifiquemos o eliminemos alguno de sus datos, por favor póngase en contacto con nosotros:</p>
                    <p>Enviando un e-mail en la sección de contacto, al email paola [arroba] lacocinasana.com</p>
                    <p>Llamándonos al +34 699 11 75 45</p>
                    <p>Escribiéndonos a c/ Jordi Solé Tura, 8, torre 1, 9A, 28055 Madrid, España.</p>
                    <p>ÚLTIMA ACTUALIZACIÓN: Abril de 2022</p>
                    <p>© Redcobot S.L, 2022 Todos los derechos reservados.</p></div>
                </div>
            </div>
        </div>
        <Newsletter />
</Layout>
  );
};

export default Politica;